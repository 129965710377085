/* You can add global styles to this file, and also import other style files */

.delete.cdk-drag-preview {
  border: 2px red solid;
  border-radius: 8px;
}

@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
